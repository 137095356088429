<template>
  <div class='examine_detail' v-loading="loading">
    <back />
    <div>
      <div style="font-size: 16px; font-weight: bold;margin:20px 0px 30px 50px;">调价信息</div>
      <el-row class="row" type="flex" align="middle">
        <el-col :span="3">
          <div class="label">商品条形码</div>
        </el-col>
        <el-col :span="7">
          <el-input v-model="form.serialId" disabled></el-input>
        </el-col>
        <el-col :span="3">
          <div class="label">商品名称</div>
        </el-col>
        <el-col :span="7">
          <el-input v-model="form.productName" disabled></el-input>
        </el-col>
        <el-col :span="3">
          <div class="label">商品分类</div>
        </el-col>
        <el-col :span="7">
          <el-input v-model="form.categoryName" disabled></el-input>
        </el-col>
      </el-row>
      <el-row class="row" type="flex" align="middle">
        <el-col :span="3">
          <div class="label">规格</div>
        </el-col>
        <el-col :span="7">
          <el-input v-model="form.specs" disabled></el-input>
        </el-col>
        <el-col :span="3">
          <div class="label">招标价格</div>
        </el-col>
        <el-col :span="7">
          <el-input v-model="form.oldPrice" disabled></el-input>
        </el-col>
        <el-col :span="3">
          <div class="label">新价格</div>
        </el-col>
        <el-col :span="7">
          <el-input v-model="form.newPrice" disabled></el-input>
        </el-col>
      </el-row>
      <el-row class="row" type="flex" align="middle">
        <el-col :span="3">
          <div class="label">申请来源</div>
        </el-col>
        <el-col :span="7">
          <el-input v-model="form.supplierName" disabled></el-input>
        </el-col>
        <el-col :span="3">
          <div class="label">申请时间</div>
        </el-col>
        <el-col :span="7">
          <el-input v-model="form.createdTime" disabled></el-input>
        </el-col>
        <el-col :span="3">
          <div class="label">审核状态</div>
        </el-col>
        <el-col :span="7">
          <el-input v-model="mapStatus" disabled :class="form.status == 0 ? 'red' : 'gray'"></el-input>
        </el-col>
      </el-row>
      <el-row class="row" type="flex" align="middle" v-if="form.status != 0">
        <el-col :span="3">
          <div class="label">审核时间</div>
        </el-col>
        <el-col :span="18">
          <el-input v-model="form.auditTime" disabled></el-input>
        </el-col>
        <el-col :span="3">
          <div class="label"></div>
        </el-col>
        <el-col :span="7">

        </el-col>

      </el-row>
      <el-row class="row" type="flex" align="middle">
        <el-col :span="3">
          <div class="label">调价原因</div>
        </el-col>
        <el-col :span="18">
          <div class="text">{{ form.adjustReason }}</div>
        </el-col>
        <el-col :span="3">
        </el-col>
        <el-col :span="7">
        </el-col>
      </el-row>
      <el-row class="row" style="color: red;" type="flex" align="middle" v-if="form.status == 2">
        <el-col :span="3">
          <div class="label">不通过原因</div>
        </el-col>
        <el-col :span="18">
          <div class="text" style="color: red;">{{ form.refuseReason }}</div>
        </el-col>
        <el-col :span="3">
        </el-col>
        <el-col :span="7">
        </el-col>
      </el-row>

      <div class="btn" v-if="form.status == 0">
        <el-button type="danger" plain @click="handleApprove(2)">不通过</el-button>
        <el-button type="primary" @click="handleApprove(1)">通过</el-button>
      </div>

      <el-dialog title="审核不通过" :visible.sync="dialogVisible" :before-close="onCancel">
        <el-form ref="examineForm" class="examineForm" :rules="rules" :model="examineForm" label-position="left" label-width="130px">
          <el-form-item label="不通过原因" prop="loseContent">
            <el-input type="textarea" v-model="examineForm.loseContent" :autosize="{ minRows: 4, maxRows: 15 }"></el-input>
          </el-form-item>
        </el-form>

        <span slot="footer">
          <el-button @click="onCancel">取 消</el-button>
          <el-button type="primary" @click="submitForm('examineForm')" :loading="submitLoding">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      id: null,
      loading: false,
      submitLoding: false,
      dialogVisible: false,
      mapStatus: '',
      form: {

      },
      examineForm: {
        loseContent: '',
      },
      rules: {
        loseContent: [{ required: true, message: "请输入不通过原因", trigger: "submit" },],
      },
    }
  },
  created () {
    this.id = this.$route.query.id
    this.getSupplierDetail()
  },
  methods: {
    getSupplierDetail () {
      this.loading = true
      this.$axios.get(this.$api.priceDetail, {
        params: {
          id: this.id
        },
      }).then((res) => {
        this.form = res.data.result.result
        this.mapStatus = this.form.status == 0 ? '待审核' : this.form.status == 1 ? '审核通过' : '审核不通过'
        this.loading = false

      }).catch(() => {
        this.loading = false
      });
    },
    // 审核通过/不通过
    handleApprove (val) {
      if (val == 1) {
        this.$confirm(`是否确认审核通过?`, "提示", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        }).then(() => {
          this.$axios.post(this.$api.priceAudit, {
            id: this.id,
            status: "1",
          }).then((res) => {
            if (res.data.code == 100) {
              this.$message.success(res.data.desc);
            }
            this.$router.go(-1)
          }).catch((err) => {
            this.$message.error(err.data.desc);
          });
        }).catch(() => {
        });
      } else {
        this.dialogVisible = true
      }
    },
    onCancel () {
      this.dialogVisible = false
    },
    // 提交表单
    submitForm (formName) {
      this.submitLoding = true
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios.post(this.$api.priceAudit, {
            id: this.id,
            refuseReason: this.examineForm.loseContent,
            status: "2",
          }).then((res) => {
            this.$message.success(res.data.desc);
            this.getSupplierDetail();
            this.submitLoding = false
            this.dialogVisible = false;
            this.$router.go(-1)
          }).catch((err) => {
            this.$message.error(err.data.desc);
          });
        } else {
          this.submitLoding = false
          this.$message.error("提交失败");
          return false;
        }
      });
    },
  },
}
</script>

<style scoped lang='scss'>
.examine_detail {
  .text {
    border: 1px solid #e5e7e6;
    width: 778px;
    height: 38px;
    line-height: 38px;
    border-radius: 10px;
    padding: 0 14px;
    font-size: 14px;
  }

  .red {
    ::v-deep .el-input__inner {
      color: #eb2500 !important;
    }
  }

  .gray {
    ::v-deep .el-input__inner {
      color: #68706e !important;
    }
  }

  .content {
    padding: 60px 190px;
  }

  .row {
    margin: 30px 0px;

    .label {
      text-align: center;
    }

    .text-red {
      color: #eb2500;
    }
  }

  ::v-deep .el-input {
    width: 255px;
  }

  ::v-deep .el-input__inner,
  ::v-deep .el-textarea__inner {
    background: #fff;
    border-radius: 10px;
    border: 1px solid #e5e7e6;
  }

  ::v-deep .el-input.is-disabled .el-input__inner,
  ::v-deep .el-textarea.is-disabled .el-textarea__inner {
    color: #1a1a1a;
  }

  .btn {
    text-align: center;
    margin: 30px 0;

    .el-button {
      width: 150px;
      margin: 0 20px;
    }
  }
}
</style>